<template>
  <div class="view-home parent-page for-parent for-parent-block position-relative">
    <div class="question-specialist" v-if="showModalQuestion">
      <div class="close-btn" @click="showModalQuestion = false">✖</div>
      <div class="item">
        Вопрос специалисту
      </div>
      <div class="item">
        <el-input placeholder="Психологу">

        </el-input>
      </div>
      <div class="item">
        <el-input placeholder="Ваше имя">

        </el-input>
      </div>
      <div class="item">
        <el-input placeholder="Ваш email">

        </el-input>
      </div>
      <div class="item">
        <el-input placeholder="Возраст вашего ребенка">

        </el-input>
      </div>
      <el-select class="item" v-model="selectRegion" placeholder="Регион">
        <el-option
            v-for="item in regions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <div class="item">
        <el-input type="textarea"
                  :rows="5">

        </el-input>
      </div>
      <el-checkbox >Согласен с условиями</el-checkbox>
      <el-button round>Отправить</el-button>
    </div>

    <div class="question-specialist" v-if="!showModalQuestion" @click="showModalQuestion=true">
      <div class="question-specialist-title">
        Вопрос специалисту
      </div>
    </div>

    <div class="parents-main">
      <div class="container d-flex flex-column">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><a href="#">Главная</a></li>
            <li class="breadcrumbs-item">Родителям</li>
          </ul>
        </nav>
        <div class="row">
          <div class="col-lg-6">
            <div class="parents-main-left">
              <el-image
                  class="main-img"
                  style="width: 100%; height: 100%"
                  src="/images/parents-main-img.png"
                  fit="contain"></el-image>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="parents-main-right">
              <h3>Родителям</h3>
              <p>
                Узнайте больше о профессиях, которые идеально подойдут вашему ребенку. Раскройте его таланты и
                способности
                с помощью нашей уникальной системы диагностики и профессиональной ориентации. Позвольте вашему ребенку
                раскрыть свой потенциал и найти путь к успешному будущему!
              </p>
              <div class="main-img">
                <el-image
                    style="width: 80%; height: 100%"
                    src="/images/parents-main-img2.png"
                    fit="contain"></el-image>
              </div>
              <div class="parents-mobile-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/parents-main-img.png"
                    fit="contain"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="definition">
      <div class="container">
        <div class="title">
          Что такое Qabilet?
        </div>
        <p class="text">
          Qabilet – служба диагностики способностей и профессиональной ориентации школьников. Вы получите рекомендации по развитию ребёнка, дальнейшему обучению и поможете ему сделать осознанный выбор профессии.
        </p>

      </div>

    </section>

    <section class="news-main" id="parentsList">
      <div class="container news-main-desktop">
        <div class="section-title" style="margin-top: 0">
          Колонка родителя
        </div>
        <div class="subtitle">
          Contrary to popular belief, Lorem Ipsum is not simply random text.
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="news-left-img">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="this.parentsList[0].image_path"
                  fit="fit"></el-image>
            </div>
            <div class="heading">
              news
            </div>
            <div class="news-left-title truncate-title">
              {{ this.parentsList[0].title }}
            </div>
            <div class="news-left-text truncate-text">
              {{ this.parentsList[0].description }}
            </div>
            <router-link :to="{ name: 'parents-list', params: { id: parentsList[0].id }}" class="news-left-link">
              Show more
            </router-link>
          </div>
          <div class="col-lg-6">
            <router-link  :to="{ name: 'parents-list', params: { id: parentsList[1].id }}" class="news-right-item"  v-if="this.parentsList[1]">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="this.parentsList[1].image_path"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title truncate-title">
                  {{ this.parentsList[1].title }}
                </div>
                <div class="news-item-left-text truncate-text">
                  {{ this.parentsList[1].description }}
                </div>
              </div>
            </router-link>
            <router-link  :to="{ name: 'parents-list', params: { id: parentsList[2].id }}" class="news-right-item" v-if="this.parentsList[2]">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="this.parentsList[2].image_path"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title truncate-title">
                  {{ this.parentsList[2].title }}
                </div>
                <div class="news-item-left-text truncate-text">
                  {{ this.parentsList[2].description }}
                </div>
              </div>
            </router-link>
            <div class="news-right-item" v-if="this.parentsList[3]">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="this.parentsList[3].image_path"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title truncate-title">
                  {{ this.parentsList[3].title }}
                </div>
                <div class="news-item-left-text truncate-text">
                  {{ this.parentsList[3].description }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="container news-main-mobile">
        <div class="mobile-line-top"></div>
        <div class="section-title">
          Колонка родителя
        </div>
        <carousel :perPage="perPageNews" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="false"
                  :paginationEnabled="false" class="carousel-mobile">
          <slide v-for="slide in this.parentsList" class="mobile-slide">
            <div class="carousel-mobile-item">
              <el-image
                  style="width: 100%; height: 100%; border-radius: 10px"
                  :src="slide.image_path"
                  fit="cover"></el-image>
              <div class="date">
                {{slide.date}}
              </div>
              <div class="title truncate-title">
                {{slide.title}}
              </div>
              <router-link :to="{ name: 'parents-list', params: { id: slide.id }}" class="more-btn">
                Read more
              </router-link>
            </div>
          </slide>
        </carousel>
      </div>
    </section>

<!--    <section class="help">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-lg-6">-->
<!--            <div class="title">-->
<!--              Как помочь своему ребенку с  выбором?-->
<!--            </div>-->
<!--            <p class="text">-->
<!--              Каждый родитель считает своего ребёнка способным и одарённым. Но часто самостоятельно сложно определить, в каком направлении нужно развивать навыки ребёнка.-->
<!--            </p>-->
<!--            <a class="link">-->
<!--              Напишите нам-->
<!--            </a>-->
<!--          </div>-->
<!--          <div class="col-lg-6">-->
<!--            <div class="questions-list">-->
<!--              <div class="question-item">-->
<!--                Как помочь ребёнку с выбором профессии?-->
<!--              </div>-->
<!--              <div class="question-item">-->
<!--                Какую специальность он выберет?-->
<!--              </div>-->
<!--              <div class="question-item">-->
<!--                Как выявить его способности и профессиональные интересы?-->
<!--              </div>-->
<!--              <div class="question-item">-->
<!--                Где получить качественную консультацию и ответы на вопросы?-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <section class="get-test">
      <div class="container get-test-img">
        <div class="title">
          Как пройти тестирование?
        </div>
        <div class="test-steps">
          <div class="test-step">
            <div class="test-step-num">
              1
            </div>
            <div class="test-step-text">
              Предложите вашему ребёнку сдать тест на определение способностей или профориентационный тест
            </div>
          </div>
          <div class="test-step">
            <div class="test-step-num">
              2
            </div>
            <div class="test-step-text">
              Зарегистрируйтесь, чтобы начать тест
            </div>
          </div>
          <div class="test-step">
            <div class="test-step-num">
              3
            </div>
            <div class="test-step-text">
              Сдайте тест онлайн или в региональном центре
            </div>
          </div>
        </div>
        <div class="test-consultation">
          <p>
            По итогам тестирования можно записаться на индивидуальную консультацию, где вы получите рекомендации по дальнейшему обучению и развитию способностей вашего ребёнка.
          </p>
          <p style="font-weight: 700; color: #1090CB">
            Все тесты прошли апробацию и подтвердили свою эффективность
          </p>
        </div>
        <div style="margin: 40px 0">
          <a class="regional-centers-link" href="#">
            Адреса региональных центров
          </a>
          <a class="reg-link" href="#">
            Регистрация
          </a>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import {Carousel, Slide} from 'vue-carousel';
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      selectRegion: "",
      showModalQuestion: true,
      perPageNews: 1,
      newsSlides: [
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
      ],
      regions:[
        {value: 1, label: "Астана", },
        {value: 2, label: "Алматы", },
        {value: 3, label: "Шымкент", },
        {value: 4, label: "Конаев", },
      ],
      parentsList: [],
    }
  },
  methods: {
    signup() {
      var url = this.$router.resolve({name: 'signup-parent', query: {'role': 'parent'}})
      window.open(url.href);
    }
  },
  mounted() {
    if (window.innerWidth < 992) {
      this.perPageNews = 2
    }

    if (window.innerWidth < 500) {
      this.perPageNews = 1
    }

    this.$http.get(window.API_ROOT + '/api/parents/list')
        .then(res => {
          this.parentsList = res.body.data
          this.parentsList.sort( (a, b) => {
            // сортирую, чтобы вначале шли свежие новости
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;
          })
        })
  }


}

</script>

<style>

.truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-left-img .el-image {
  max-height: 290px;
  border-radius: 10px;
}

.news-item-right .el-image {
  border-radius: 10px;
}

.parents-mobile-img {
  display: none;
}

.news-main-mobile,
.mobile-line-top,
.carousel-mobile

{
  display: none;
}

.question-specialist {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 20px;
  background-color: #2073a0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 5;
}

.question-specialist-title {
  color: #FFF;
  cursor: pointer;
}

.question-specialist .item {
  width: 290px;
  color: #fff;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #fff;
}

.question-specialist .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .get-test-img::after {
    background-image: none;
  }
}

@media screen and (max-width: 992px) {

  .parents-mobile-img {
    display: block;
  }

  .parents-main-left {
    display: none;
  }

  .main-img {
    display: none;
  }

  .parents-main-right {
    padding-top: 50px;
  }

  .parents-mobile-img {
    margin-bottom: -50px;
    padding-top: 30px;
    padding-left: 30px;
    position: relative;
  }

  .parents-mobile-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 90%;
    background: #1090CB;
    border-radius: 10px;
  }

  .definition {
    background-image: none;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .definition .text {
    width: 100%;
  }

  .questions-list {
    margin-top: 50px;
  }

  .get-test {
    margin-top: 30px;
  }

  .get-test .test-steps {
    flex-direction: column;
  }

  .test-steps .test-step {
    border-top: 1px solid #FFF;
    padding-top: 20px;
  }

  .test-step .test-step-num {
    font-size: 24px;
  }

  .test-step .test-step-text {
    font-size: 16px;
  }

  .get-test::after {
    background-image: none;
  }

  .get-test-img::after {
    background-image: none;
  }

  .test-consultation {
    width: 100%;
    padding: 20px;
    padding-top: 120px;
  }

  .test-consultation::before {
    top: 20px;
  }

  .regional-centers-link {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .reg-link {
    display: inline-block;
    width: 100%;
    margin-left: 0 !important;
    margin-top: 30px;
    text-align: center;
  }

  .news-main-mobile,
  .mobile-line-top,
  .carousel-mobile

  {
    display: block;
  }

  .news-main-mobile .section-title {
    font-size: 30px;
    font-weight: 800;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .carousel-mobile-item {
    padding-right: 20px;
  }

  .carousel-mobile-item .date {
    font-weight: 400;
    font-size: 14px;
    color: #9497A1;
    margin: 20px 0 12px 0;
  }

  .carousel-mobile-item .title {
    font-weight: 700;
    font-size: 20px;
    color: #32436D;
  }

  .carousel-mobile-item .more-btn {
    font-weight: 600;
    font-size: 16px;
    color: #185CFF;
    margin: 12px 0;
    position: relative;
    display: inline-block;
    padding-right: 30px;
  }

  .carousel-mobile-item .more-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../public/images/icon_arrow_right.svg");
  }

  .parent-page .news-main-desktop {
    display: none;
  }

}

</style>
